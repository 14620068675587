import React from "react"

const Profile = () => {

  return(
<div className="my-4 px-4">
<h1 className="headers text-6xl">
                About Jax
            </h1>

    <div className="my-8 flex flex-col md:h-[500px] items-center justify-center p-8 headers text-xl md:text-5xl">
Under Construction
    </div>
    </div>
    
  )
}

export default Profile